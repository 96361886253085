/* Default styles for both light and dark modes */
html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
    ::-webkit-scrollbar-thumb {
        background: black;
    }
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
    ::-webkit-scrollbar-thumb {
        background: orange;
    }
}

h2 {
    overflow: hidden;
}
