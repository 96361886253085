.switch{
    float: right;
    position: relative;
    right: 13%;
}

.option{
    margin: 0.5rem;
}

.leftSlide{
    overflow: hidden;
    position: relative;
    height: max-content;
    padding: 1rem 0rem 3rem 5rem;
}

.course{
    margin-bottom: 1rem;
    transition: all 0.5s;

}

.course:hover{
    transform: translateY(1rem);
}

.sidebar-components{
    display: flex;
}
.icon-sidebar{
    margin-top: 10px;
    font-size: 26px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.icon-sidebar:hover {
    transform: scale(1.3);
}